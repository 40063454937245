import { useRoutes } from "react-router-dom"
import { MainAppRoutes } from "./MainAppRoutes"
import { AuthenticationRoutes } from "./AuthenticationRoutes"
import useStyles from "./style-jss"
import useCookiesFunction from "utils/cookies.utils"

export function AppRoute() {
  const classes = useStyles() as any
  const { cookies } = useCookiesFunction()

  let routes

  if (!cookies.is_logged_in) {
    routes = [AuthenticationRoutes({ classes })]
  } else {
    routes = [MainAppRoutes({ classes })]
  }

  return useRoutes(routes)
}

export default AppRoute
