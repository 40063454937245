import { createTheme } from "@mui/material/styles"

/* ASSETS */
import colors from "assets/scss/_themes-vars.module.scss"

/* PROJECT IMPORTS */
import componentStyleOverrides from "./compStyleOverride"
import themePalette from "./palette"

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization
 */

export const theme = (customization: any) => {
  const color = colors

  const themeOption = {
    colors: color,
    dominos: {
      dominos_dark_blue: color.dominos_dark_blue,
      dominos_blue: color.dominos_blue,
      dominos_red: color.dominos_red,
      dominos_white: color.dominos_white,
      dominos_yellow: color.dominos_yellow,
      grey1: color.grey1,
      grey2: color.grey2,
      grey3: color.grey3,
    },
    heading: color.black,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.black,
    darkTextSecondary: color.black,
    textDark: color.black,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    customization,
  }

  const themeOptions = {
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "65px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "65px",
        },
      },
    },
  } as object

  const themes = createTheme(themeOptions)
  themes.components = componentStyleOverrides(themeOption)

  return themes
}

export default theme
