export const gridSpacing = 3
export const drawerWidth = 245
export const appDrawerWidth = 320
export const utc_time_zone = "+04:00"
export const dateFormat = "DD.MMM.YYYY"
export const timeFormat = "HH:mm"
export const timeFormatWithSec = "HH:mm:ss"
export const secret_key = "D@MIN@S-DO_2035!"
export const BIRTHDAY_COUPON_CODE = "_BD101"
export const NEW_CUS_COUPON_CODE = "_NC101"
