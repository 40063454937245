import ResetPassword from "views/reset_password"
import { Login, ForgotPassword } from "./PageAsync"
import PropTypes from "prop-types"

export function AuthenticationRoutes({ classes }: any) {
  const AuthRoutes = {
    path: "/",
    children: [
      {
        path: "/",
        element: <Login classes={classes} />,
      },
      {
        path: "/login",
        element: <Login classes={classes} />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword classes={classes} />,
      },
      {
        path: "/reset-password/:id",
        element: <ResetPassword classes={classes} />,
      },
      {
        path: "/*",
        element: <Login classes={classes} />,
      },
    ],
  }

  return AuthRoutes
}

AuthenticationRoutes.propTypes = {
  classes: PropTypes.object,
}
