import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import apiServices from "services/RequestHandler"

export interface LoggerState {
  create_logger: any
  activity: boolean
}

interface APIParams {
  end_point: string
  body: any
}

const initialState: LoggerState = {
  create_logger: {},
  activity: true,
}

export const create_logger = createAsyncThunk(
  "/logger/create",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "logger"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const loggers_slicer = createSlice({
  name: "loggers",
  initialState,
  reducers: {
    handle_clear_form: (state: LoggerState, action: any) => {
      state.create_logger = {}
      state.activity = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create_logger.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(create_logger.fulfilled, (state, { payload }) => {
        try {
          state.create_logger = { ...payload, call_time: new Date() }
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })
  },
})

export const { handle_clear_form } = loggers_slicer.actions

export default loggers_slicer.reducer
