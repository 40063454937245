import { useCookies } from "react-cookie"
const useCookiesFunction = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "is_logged_in",
    "token",
  ])

  return { cookies, setCookie, removeCookie }
}

export default useCookiesFunction
