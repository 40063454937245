import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import config from "./config"
import { Provider } from "react-redux"
import { store } from "store"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment"
import { CookiesProvider } from "react-cookie"
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.Fragment>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-US"}>
      <Provider store={store}>
        <BrowserRouter basename={config.basename}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </BrowserRouter>
      </Provider>
    </LocalizationProvider>
  </React.Fragment>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
